<template>
    <div class="current-time py-3 text-center">
        <h5 class="mb-0">{{ dateTimeString }}</h5>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "current-time",

        data() {
            return {
                interval: null
            }
        },

        computed: {
            ...mapState('calendar', ['items']),
            ...mapGetters('calendar', ['dateTimeString'])
        },

        methods: {
            setTime() {
                this.$store.commit('calendar/setTime')
            }
        },

        mounted() {
            this.interval = window.setInterval(this.setTime, 1000);
        }
    }
</script>

<style scoped>

</style>