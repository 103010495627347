<template>
    <div class="py-4 my-4" :class="{ 'blur' : blur, 'bg-light' : !active, 'bg-primary text-white' : active }">
        <div class="row align-items-center">
            <div class="col-3">
                <span class="ml-3 badge" :class="badgeClass">{{ badgeTime }}</span>
            </div>
            <div class="col">
                <h2 class="font-weight-bold mb-0">{{ plateNumber }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import {DateTime} from "luxon";

    export default {
        name: "tile",

        props: {
            blur: {
                type: Boolean,
                default: false
            },

            active: {
                type: Boolean,
                default: false
            },

            time: {
                type: String,
                required: true
            },

            plateNumber: {
                type: String,
                required: true
            }
        },

        computed: {
            badgeClass () {
                return this.active ? 'bg-white text-dark' : 'badge-primary';
            },

            badgeTime () {
                return DateTime.fromSQL(this.time).setLocale('hu').toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .badge {
        font-size: 1.2rem;
    }

    .blur {
        filter: blur(1px);
        opacity: 0.7;
    }
</style>