<template>
    <div class="container-fluid d-flex flex-column h-100">
        <div class="row flex-grow-1 no-gutters">
            <column title="Autó" workshop="A"></column>
            <column title="Motor" workshop="M"></column>
            <column title="Klíma" workshop="K"></column>
        </div>
        <current-time></current-time>
    </div>
</template>

<script>
    import CurrentTime from "@/components/current-time";
    import Column from "@/components/column";

    export default {
        name: 'App',

        components: {
            Column,
            CurrentTime,
        },

        data() {
            return {
                appInterval: null
            }
        },

        created() {
            this.$store.dispatch('calendar/fetch');

            this.appInterval = window.setInterval(function () {
                this.$store.dispatch('calendar/fetch');
            }, 15 * 60 * 1000);
        }
    }
</script>

<style lang="scss">
    @import '~bootstrap';
    @import '~bootstrap-vue';

    html, body, #app {
        height: 100%;
    }
</style>
