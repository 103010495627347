<template>
    <div class="col border">
        <header class="text-center bg-dark py-3">
            <h4 class="text-white text-uppercase font-weight-bold">{{ title }}</h4>
        </header>

        <tile
            v-if="last"
            blur
            :time="last.idopont"
            :plate-number="last.rendszam"
        ></tile>

        <tile
            v-if="actives.length"
            active
            :time="activeTime"
            :plate-number="activePlateNumbers"
        ></tile>

        <tile
            v-for="(next, key) in nextTwo"
            :key="key"
            :time="next.idopont"
            :plate-number="next.rendszam"
        ></tile>

    </div>
</template>

<script>
    import Tile from "@/components/tile";
    import {mapGetters} from "vuex";
    import _ from 'lodash';
    import {DateTime} from "luxon";

    export default {
        name: "column",
        components: {Tile},
        props: {
            title: {
                type: String,
                required: true
            },
            workshop: {
                type: String,
                required: true
            }
        },

        computed: {
            ...mapGetters('calendar', [
                'workshopItems',
                'dateTime'
            ]),

            items: function () {
                return this.workshopItems(this.workshop);
            },

            activePlateNumbers: function () {
                if (! _.isEmpty(this.actives)) {
                    return _.map(this.actives, 'rendszam').join(", ");
                }

                return null;
            },

            activeTime: function () {
                if (! _.isEmpty(this.actives)) {
                    return _.head(this.actives).idopont;
                }

                return null;
            },

            actives: function () {
                let self = this;
                return _.filter(this.items, function (item) {
                    return self.dateTime.minus({minutes: 30}) <= DateTime.fromSQL(item.idopont)
                        && self.dateTime > DateTime.fromSQL(item.idopont);
                });
            },

            nextTwo: function () {
                if (_.isArray(this.followings)) {
                    return _.take(this.followings, 2);
                }

                return [];
            },

            followings: function () {
                let self = this;
                return _.filter(this.items, function (item) {
                    return self.dateTime < DateTime.fromSQL(item.idopont);
                });
            },

            last: function () {
                let self = this;
                let lastItems = _.filter(this.items, function (item) {
                    let idopont = DateTime.fromSQL(item.idopont);
                    return self.dateTime.minus({ minutes:30 }) > idopont;
                });

                if (! _.isEmpty(lastItems) && this.actives.length) {
                    return _.last(lastItems);
                }

                return null;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>