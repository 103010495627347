import {DateTime} from "luxon";
import _ from 'lodash';

const state = {
    loading: false,
    items: [],
    currentTime: DateTime.local()
}

const getters = {
    dateTimeString: (state) => {
        return state.currentTime.setLocale('hu').toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    },

    dateTime: (state) => {
        return state.currentTime;
    },

    workshopItems: (state) => (workshop) => {
        return _.filter(state.items, function (item) {
            return item.workshop === workshop;
        });
    },
}

const actions = {
    fetch ({ commit }) {
        fetch(process.env.VUE_APP_REMOTE_URL)
            .then(response => response.json())
            .then(response => {
                commit('setItems', response);
            });
    }
}

const mutations = {
    setItems (state, items) {
        state.items = items;
    },

    setTime (state) {
        // state.currentTime = DateTime.local().minus({minutes: 90});
        state.currentTime = DateTime.local();
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}